const CloudOptimizationAWSComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="row d-flex">
                        <h3>Cloud optimization for Amazon web services</h3>
                        <p>Equally important but very different.</p>
                      </div>
                      <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/logo-amazon-web-services.svg" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-page">
              <div id="Solutions" className="section">
                <div className="top-icon-box position-relative">
                  <div className="container position-relative">
                    <div className="row gy-4">
        
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/ec2-icon.png" style={{width:"100px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon EC2 Optimization</a></h4>
                        </div>
                      </div>
        
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/amazon-rds.icon.png" style={{width:"80px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon RDS Optimization</a></h4>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/amazon-asg.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon ASG Optimization</a></h4>
                        </div>
                      </div>
        
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/eks-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon EKS Clusters Optimization</a></h4>
                        </div>
                      </div>
                    </div>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="section-header">
                          <h2>Amazon EC2 Optimization</h2>
                        </div>
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <p>FinopsCloud’s machine learning analyzes workload patterns to determine the optimal size and family
                                for your AWS Elastic Compute Cloud (EC2) instances, enabling you to:</p>
                              <ul>
                                <li>Reduce application performance and stability issues associated with under allocating
                                  resources to your instances
                                </li>
                                <li>Increase utilization and lower costs by ensuring that you are not overallocating capacity to
                                  your instances
                                </li>
                                <li>Avoid leaving CPU, memory or other resources stranded by picking the wrong instance family.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
        
                        <div className="section-header">
                          <h2>Amazon RDS Optimization</h2>
                        </div>
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <p>Optimize your AWS Relational Database Services (RDS) using FinopsCloud’s machine learning based
                                analysis, ensuring that you:
                              </p>
                              <ul>
                                <li>Reduce application performance and stability issues associated with under allocating
                                  resources to your database
                                </li>
                                <li>Increase utilization and lower costs, for one of the most expensive service types, by
                                  ensuring that you are not overallocating capacity to your database
                                </li>
                                <li>Avoid leaving CPU, memory or other resources stranded by picking the wrong instance family
                                </li>
                                <li>Protect your existing RI investment by identifying when an RDS instance is covered by an RI
                                  and deffering the recommendation until it expires
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>Amazon ASG Optimization
                              </h2>
                              <p>FinopsCloud analyzes the utilization and scaling behavior of AWS Auto Scaling Groups (ASGs) and
                                performs simulations to determine their optimal configuration, enabling you to:
                              </p>
                              <ul>
                                <li>Analyze whether scaling groups are elastic, or are constrained by resource configurations
                                </li>
                                <li>Determine optimal scaling parameters such as the minimum and maximum size of your ASG
                                </li>
                                <li>Highlight when your scaling policies should be reviewed because nodes are not being added
                                  and removed in line with workload demand
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="section-header">
                            <h2>Amazon EKS Clusters Optimization
                            </h2>
                          </div>
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <p>
                              FinopsCloud’s analytics predictively, precisely and continuously determine the appropriate resource
                                settings for your AWS Elastic Kubernetes Service (EKS) containers and nodes allowing you to:
                              </p>
                              <ul>
                                <li>Avoid application performance and stability issues
                                </li>
                                <li>Visualize the overall resource health of your entire Kubernetes environment with Histograms
                                </li>
                                <li>Increase node and cluster utilization by avoiding allocating too much CPU and memory to your
                                  applications
                                </li>
                                <li>Automatically assign the ideal amount of CPU and memory for the nodes and node groups in
                                  your clusters
                                </li>
                                <li>Determine the right instance families for your nodes
                                </li>
                                <li>Automatically model scaling behavior to optimize scale groups
                                </li>
                                <li>Lower your cloud bill by deploying fewer nodes for the same containers
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>Visualize Utilization Metrics for Your Google Cloud Infrastructure
                              </h2>
                              <p>FinopsCloud provides instant access to visualize CPU, Memory, Network and Disk I/O utilization
                                metrics for your GCE instances, enabling you to:
                              </p>
                              <ul>
                                <li>Be confident in implementing FinopsCloud’s optimization recommendations by visualizing
                                  historical utilization patterns.
                                </li>
                                <li>Select the historical period, hourly or daily time granularity, and multiple statistics of
                                  interest including the peak, sustained, average and minimum.
                                </li>
                                <li>ICompare the utilization of different GCE resources using the four available charts or by
                                  selecting multiple metrics on a single chart.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>Integrate With CI/CD Pipelines & Automation Tools
                              </h2>
                              <ul>
                                <li>Free your teams from manually selecting resources
                                </li>
                                <li>Eliminate errors – use APIs to tie directly into infrastructure as code templates like
                                  Terraform or Google Cloud Deployment Manager
                                </li>
                                <li>Ensure Performance by continuously aligning resources with application requirements
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </main>
    );
}

export default CloudOptimizationAWSComponent;