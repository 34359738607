const CloudOptimizationAzureComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="row d-flex">
                          <h3>Cloud optimization for Microsoft Azure</h3>
                          <p>Equally important but very different.</p>
                      </div>
                      <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/logo-microsoft-azure.svg" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-page">
              <div id="Solutions" className="section">
                <div className="top-icon-box position-relative">
                  <div className="container position-relative">
                    <div className="row gy-4">
        
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/azure-vm-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Azure VM Optimization​</a></h4>
                        </div>
                      </div>
        
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/azure-aks-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Azure AKS Optimization​</a></h4>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/visualize-metrics-gcp-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Visualize Metrics for Google Cloud
                              Infrastructure</a></h4>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/cicd-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Integrate With CI/CD Pipelines & Automation
                              Tools</a></h4>
                        </div>
                      </div>
                    </div>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="section-header">
                          <h2>Azure VM Optimization​
                          </h2>
                        </div>
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <p>FinopsCloud’s machine learning analyzes workload patterns to determine the optimal size and family for your Azure Virtual Machines (VMs), enabling you to: 
                              </p>
                              <ul>
                                <li>Reduce application performance and stability issues associated with under allocating resources to your VMs
                                </li>
                                <li>Increase utilization and lower costs by ensuring that you are not overallocating capacity to your VMs
                                </li>
                                <li>Avoid leaving CPU, memory or other resources stranded by picking the wrong instance family
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
        
                        <div className="section-header">
                          <h2>Azure AKS Optimization​
                          </h2>
                        </div>
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <p>FinopsCloud’s analytics predictively, precisely and continuously determine the appropriate resource settings for your Azure Kubernetes Service (AKS) containers allowing you to: 
                              </p>
                              <ul>
                                <li>Avoid application performance and stability issues
                                </li>
                                <li>Visualize the overall resource health of your entire Kubernetes environment with Histograms
                                </li>
                                <li>Increase node and cluster utilization by avoiding allocating too much CPU and memory to your
                                  applications
                                </li>
                                <li>Ensure cluster resource and namespace quotas are constantly aligned with app team
                                  requirements
                                </li>
                                <li>Lower your cloud bill by deploying fewer nodes for the same containers
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>Visualize Utilization Metrics for Your Google Cloud Infrastructure
                              </h2>
                              <p>FinopsCloud provides instant access to visualize CPU, Memory, Network and Disk I/O utilization
                                metrics for your GCE instances, enabling you to:
                              </p>
                              <ul>
                                <li>Be confident in implementing FinopsCloud’s optimization recommendations by visualizing
                                  historical utilization patterns.
                                </li>
                                <li>Select the historical period, hourly or daily time granularity, and multiple statistics of
                                  interest including the peak, sustained, average and minimum.
                                </li>
                                <li>ICompare the utilization of different GCE resources using the four available charts or by
                                  selecting multiple metrics on a single chart.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section id="whyus" className="about">
                      <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>Integrate With CI/CD Pipelines & Automation Tools
                              </h2>
                              <ul>
                                <li>Free your teams from manually selecting resources
                                </li>
                                <li>Eliminate errors – use APIs to tie directly into infrastructure as code templates like Terraform or Google Cloud Deployment Manager
                                </li>
                                <li>Ensure Performance by continuously aligning resources with application requirements
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
        </main>
    );
}

export default CloudOptimizationAzureComponent;