import { MainComponent } from "../main";

const LayoutCompnent = () => {
    //TODO: Fix move side panels, footer outisde and only route mainCmponent
    return(
        <>
        <MainComponent />
        </>
    );
}

export default LayoutCompnent;