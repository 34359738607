const BookDemoComponent =() => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                      <h2>Optimize your cloud spending with Finops-Cloud</h2>
                      <p>Schedule your 30 minute personalized tour of the SinopsCloud cloud optimization platform
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/aws-azure-google.png" className="img-fluid rounded-4 mb-4 hero-icon" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="single-page">
                  <div className="container">
                    <div className="row">
                          <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                              <h2>
                                See the Benefits of Optimized Cloud & Container Resources
                              </h2>
                              <ul>
                                <li>Dramatically reduce cloud costs and infrastructure spend through collaborative FinOps
                                </li>
                                <li>Automate resource selection for cloud, container, and on-prem workloads and achieve better
                                  application performance
                                </li>
                                <li>Leverage the latest and most efficient public cloud services and infrastructure offerings
                                </li>
                                <li>Extend your CI/CD pipeline with continuous optimization</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="page">
                              <div className="login">
                                <div className="wrapper">
                                  <form name="signin" className="form">
                                    <div className="input-control">
                                      <label className="input-label" hidden>Full Name</label>
                                      <input type="text" name="name" id="name" className="input-field" placeholder="Full Name" />
                                    </div>
                                    <div className="input-control">
                                      <label className="input-label" hidden>Phone</label>
                                      <input type="phone" name="phone" id="phone" className="input-field" placeholder="Phone" />
                                    </div>
                                    <div className="input-control">
                                      <label className="input-label" hidden>Company Email Address</label>
                                      <input type="email" name="email" id="email" className="input-field"
                                        placeholder="Company Email Address" />
                                    </div>
                                    <div className="input-control">
                                      <input type="submit" name="submit" className="input-submit" value="Book A Demo" disabled />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
            </section>
              <div className="section-header">
                <h2>See Optimization Results Across</h2>
                <div className="row gy-4">
                  <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
                    style={{margin: "auto", width: "500px"}} alt="" />
                </div>
              </div>
            <section id="whyus" className="about"
              style={{background: "linear-gradient(140deg, #0021630a 34%, #0071e900 100%)"}}>
              <div className="container landing-resources blue-box" style={{borderRadius: "40px"}} data-aos="fade-up">
                <div className="d-flex" data-aos-delay="2000" data-aos="zoom-out">
                  <h2 className="landing-booking">Start now with FinopsCloud.ai</h2>
                </div>
                <p className="typo-center">
                  Discover how much you could save by connecting your infrastructure with FinopsCloud.ai for free.
                </p>
                <div className="subscribe-form" style={{width: "500px", marginLeft: "350px"}}>
                  <form action="#">
                    <input type="text" placeholder="Email Address" />
                    <button><i className="fa fa-telegram"></i></button>
                  </form>
                </div>
              </div>
            </section>
        </main>
    );
}
export default BookDemoComponent;