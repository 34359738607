
import {Link} from 'react-router-dom'
const SidePanelSection = () => {
    return (
        <header id="header" className="header d-flex align-items-center">
        <div className="container container-xl d-flex align-items-center justify-content-between">
          <Link to="/landing" className="logo d-flex align-items-center">
            <img src="assets/images/logo.png" alt="logo" />
          </Link>
          <nav id="navbar" className="navbar">
            <ul>
              <li className="dropdown"><Link to="product"><span>Products</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li><Link to="product-cost-monitoring">Multi-cloud Cost Monitoring</Link></li>
                  <li><Link to="product-workload-monitoring">Multi-cloud workloads Monitoring</Link></li>
                  <li><Link to="product-optimizer">Multi-cloud resource optimizer</Link></li>
                </ul>
              </li>
              <li className="dropdown"><Link to="solutions"><span>Solutions</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li><Link to="resource-optimization-within-finops-strategy">Resource optimization within finops strategy</Link></li>
                  <li><Link to="resource-optimization-vs-cloud-cost">Resource optimization vs cloud cost management</Link></li>
                  <li><Link to="cloud-optimization-google">Cloud optimization for Goolge Cloud</Link></li>
                  <li><Link to="cloud-optimization-aws">Cloud optimization for Amazon web services</Link></li>
                  <li><Link to="cloud-optimization-azure">Cloud optimization for Microsoft Azure</Link></li>
                </ul>
              </li>
              <li><Link to="pricing">Pricing</Link></li>
              <li><Link to="resources">Resources</Link></li>
              <li><Link to="contact">Contact</Link></li>
            </ul>
            <Link to={{ pathname: "https://portal.finopscloud.ai" }} target='_blank' className="header-start" style={{padding:"12px 30px"}}>GET STARTED</Link>
            <Link to={{ pathname: "https://portal.finopscloud.ai" }} target='_blank' className='header-login' style={{padding:"12px 30px"}}>LOGIN</Link>
          </nav>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        </div>
      </header>
    );
}

export default SidePanelSection;