 
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { ProductComponent } from '../product';
import { CostMonitoringComponent } from '../product-cost-monitoring';
import { WorkloadMonitoringComponent } from '../product-workload-monitoring';
import { OptimizerComponent } from '../product-optimizer';
import { SolutionsComponent } from '../solutions';
import { ResOptimizationComponent } from '../solutions-res-optimization';
import { ResOptimizationCloudCostComponent } from '../solutions-res-optimization-vs-cloud-cost';
import { CloudOptimizationAWSComponent } from '../solutions-cloud-optimization-aws';
import { CloudOptimizationAzureComponent } from '../solutions-cloud-optimization-azure';
import { CloudOptimizationGCPComponent } from '../solutions-cloud-optimization-gcp';
import { PricingComponent } from '../pricing'
import { ResourcesComponent } from '../resources'
import { ContactInfoComponent } from '../contact'
import { TermsComponent } from '../terms'
import { PrivacyComponent } from '../privacy'
import { SecurityComponent } from '../security'
import { BookDemoComponent } from '../book-demo'
import { HomeComponent } from '../home';
import { ComingSoonComponent } from '../coming-soon';
import { SidePanelSection } from '../sidepanel';
import { FooterComponent } from "../footer";
 
const MainComponent = () => {
    return (
        <Router> 
            <Switch> 
                <Route path ="/" exact >
                    <>
                        <main id="main">
                            <ComingSoonComponent />      
                        </main>
                        
                    </>
                </Route>

                <Route path ="/landing" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <HomeComponent />      
                        </main>
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/product" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <ProductComponent />   
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/product-cost-monitoring" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <CostMonitoringComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </> 
                </Route>

                <Route path ="/product-workload-monitoring" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <WorkloadMonitoringComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/product-optimizer" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <OptimizerComponent/> 
                        </main> 
                        <FooterComponent /> 
                    </>  
                </Route>

                <Route path ="/solutions" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <SolutionsComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/resource-optimization-within-finops-strategy" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <ResOptimizationComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/resource-optimization-vs-cloud-cost" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <ResOptimizationCloudCostComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/cloud-optimization-aws" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <CloudOptimizationAWSComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/cloud-optimization-azure" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <CloudOptimizationAzureComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/cloud-optimization-google" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <CloudOptimizationGCPComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/pricing" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <PricingComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/resources" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <ResourcesComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/contact" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <ContactInfoComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/terms" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <TermsComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/privacy" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <PrivacyComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/security" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <SecurityComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

                <Route path ="/book-demo" exact >
                    <>
                        <SidePanelSection />
                        <main id="main">
                            <BookDemoComponent /> 
                        </main> 
                        <FooterComponent /> 
                    </>
                </Route>

            </Switch>   
        </Router>
    );
}

export default MainComponent;