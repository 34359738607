import { Link } from "react-router-dom";

const PricingComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                      <h2>Pricing</h2>
                      <p>Our incentives are aligned to optimize your Cloud costs.
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/aws-azure-google.png" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
          <section className="single-page">
            <div id="pricing" className="pricing section">
              <div className="container-fluid">
                <div className="container">
                  <div className="section-header">
                    <h2>The Cloud Optimization Platform For</h2>
                    <div className="row gy-4">
                      <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
                        style={{margin: "auto", width: "500px"}} alt="" />
                    </div>
                    <p>We make it easy to get started with our free Cloud Management Platform and multiple products that are
                      based on your cloud savings.</p>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="card text-center">
                        <div className="title">
                          <img src="assets/images/icons/pricing-basic.svg" alt="icon" />
                          <h2>Single Cloud</h2>
                        </div>
                        <div className="price">
                          <h4>Free</h4>
                        </div>
                        <div className="option">
                          <ul>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Workload Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Cost Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Recommendation</li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="false"></i> Resource Optimization</s></li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="false"></i> Kubernetes Optimization</s></li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="false"></i> CI/CD Cost Automation</s></li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="true"></i> Report / Billing</s></li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="false"></i> Live Support</s></li>
                          </ul>
                          
                        </div>
                        <a href="#">Order Now</a>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="card text-center">
                        <div className="title">
                          <img src="assets/images/icons/pricing-standard.svg" alt="icon" />
                          <h2>Double Cloud</h2>
                        </div>
                        <div className="price">
                          <h4>25<sup>%</sup></h4>
                        </div>
                        <div className="option">
                          <ul>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Workload Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Cost Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Recommendation</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> Resource Optimization</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> Kubernetes Optimization</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> CI/CD Cost Automation</li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="true"></i> Report / Billing</s></li>
                            <li><s><i className="bi bi-check-circle" aria-hidden="false"></i> Live Support</s></li>
                          </ul>
                        </div>
                        <a href="#">Order Now</a>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="card text-center">
                        <div className="title">
                          <img src="assets/images/icons/pricing-premium.svg" alt="icon" />
                          <h2>Triple Cloud</h2>
                        </div>
                        <div className="price">
                          <h4>40<sup>%</sup></h4>
                        </div>
                        <div className="option">
                          <ul>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Workload Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Cost Monitoring</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Recommendation</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> Resource Optimization</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> Kubernetes Optimization</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> CI/CD Cost Automation</li>
                            <li><i className="bi bi-check-circle" aria-hidden="true"></i> Report / Billing</li>
                            <li><i className="bi bi-check-circle" aria-hidden="false"></i> Live Support</li>
                          </ul>
                        </div>
                        <a href="#">Order Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      
        </main>
    );
}

export default PricingComponent;