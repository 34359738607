import React from 'react'; 
import { LayoutCompnent } from '../layout';

function App() {
  return (
     <LayoutCompnent />
  );
}

export default App;
