const TermsComponent =() => {
    return (
          <main id="main">
          <div className="breadcrumbs">
            <div className="page-header d-flex align-items-center">
              <div className="container position-relative">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 text-center">
                    <h2>Terms & Conditions</h2>
                    <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                  </div>
                </div>
              </div>
            </div>
            <nav>
              <div className="container">
                <ol>
                  <li><a href="/">Home</a></li>
                  <li>Terms & Conditions</li>
                </ol>
              </div>
            </nav>
          </div>
      
          <section className="single-page">
            <div className="container" data-aos="fade-up">
      
              <p>
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio
                voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <h3>Sub Heading</h3>
              <ul>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Nemo enim ipsam voluptatem quia voluptas sit aspernatur</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Odio et unde deleniti. Deserunt numquam exercitationem.
                  Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus
                  dolores. Quasi ratione sint. Sit quaerat ipsum dolorem. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Xonsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio et unde deleniti. Deserunt numquam
                  exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis
                  veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Xonsectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio et unde deleniti.
                  Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit
                  dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Neque porro quisquam est, qui dolorem ipsum quia amet Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Rempora incidunt ut labore et dolore magnam aliquam Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Neque porro quisquam est, qui dolorem ipsum quia amet Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.</li>
              </ul>
      
              <p></p>
      
              <p>
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio
                voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio
                voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
      
              <p>
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio
                voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
      
              <p>
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio
                voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <h3>Sub Heading</h3>
              <ul>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Nemo enim ipsam voluptatem quia voluptas sit aspernatur</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Sed quia consequuntur magni dolores eos qui ratione</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Neque porro quisquam est, qui dolorem ipsum quia amet</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Consectetur, adipisci velit, sed quia non numquameius</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Rempora incidunt ut labore et dolore magnam aliquam</li>
                <li><i className="bi bi-arrow-right-circle-fill"></i> Neque porro quisquam est, qui dolorem ipsum quia amet</li>
              </ul>
      
            </div>
          </section>
      
        </main>
    );
}
export default TermsComponent;