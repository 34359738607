const ProductComponent = () => {
  return (
    <main id="main">
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h3>FinOpsCloud Products</h3>
                <p>Get rid of Overspending, Improve Performance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-header">
        <br/>
        <h2>FinopsCloud products for AWS, Azure & Google Cloud Platform
</h2>
        <div className="row gy-4">
          <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
            style={{ margin: "auto", width: "500px" }} alt="" />
        </div>
      </div>

      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>Having visibility of cost and resources it's time to optimize for maximum utilization
            </h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <p>Optimizing cloud resources means going beyond financial analysis to impact resource efficiency. Choosing sub optimal instances can be a cost or performance disaster in the cloud:​            </p>
                <ul>
                  <li>Compare results between accounts, business groups, applications, or by any other tag
                  </li><li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                  </li><li>Assess the impact of implementing optimization recommendations
                  </li><li>Calculate financial impacts and estimated savings</li>
                </ul>
                <p>Read more about <a href="#">resource optimization vs cloud cost management</a>.​</p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-monitoring.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Infra workload visibility and resource forecasting
                </h2>
                <br />
                <p>
                  Achieve Top-Down Capacity Visibility Across Your Cloud Infrastructure
                  FinopsCloud combines detailed analysis of thousands of entities into a dynamic view of the scope, efficiency, and health across all your cloud environments. This lets you:
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li><li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li><li>Assess the impact of implementing optimization recommendations
                    </li><li>Calculate financial impacts and estimated savings</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Support for multiple cloud optimization
                </h2>
                <br />
                <p>
                  FinopsCloud automatically produces Impact Analysis and Recommendation Reports to share with stakeholders, letting you:
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li><li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li><li>Assess the impact of implementing optimization recommendations</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-optlize.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
          <div className="col-lg-6">
              <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Cloud Migration
                </h2>
                <br />
                <p>
                  As per resource and cost analysis we help to migrate to other cost effective cloud provider
                  <ul>
                    <li>We compare the similar services resource and cost w.r.t multiple cloud providers
                    </li><li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li><li>Assess the impact of considering other cloud service provider</li>
                  </ul>
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Recomendations
                </h2>
                <br />
                <p>
                  As we consider our clients with utmost priority, We provide effective recomendations
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li><li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li><li>Assess the impact of implementing optimization recommendations</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-optlize.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>

    </main>
  );
}

export default ProductComponent;