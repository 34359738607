import { Link } from "react-router-dom";

const SolutionsComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="row d-flex">
                        <h3>Multi-Cloud Finops Solutions</h3>
                        <p>Equally important but very different.</p>
                      </div>
                      <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/multi-cloud.png" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-page">
              <div id="Solutions" className="section">
                <div className="top-icon-box position-relative">
                  <div className="container position-relative">
        
                    <div className="section-header">
                      <h3>Finops cloud AI provides given below solution to optimize aws, google cloud and azure resources.</h3>
                    </div>
                    <div className="row gy-4">
        
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/Kubernetes-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><Link to="" className="stretched-link">Visualize Kubernetes Cost & Performance Risks</Link>
                          </h4>
                          <p>Histograms that let you spot under and over provisioned resources at a glance.
                            Quickly drill down to see details and recommended configurations</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/cicd-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><Link to="" className="stretched-link">Integrate With CI/CD Pipelines & Automation
                              Tools</Link></h4>
                          <p>Free your teams from manually selecting resources.
                            Use APIs to effect changes with infrastructure as code tools like Terraform or CloudFormation</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/container-icon.png" style={{width: "60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Automatically Set Container Resource Requests &
                              Limits</a></h4>
                          <p>Avoid allocating too much or too little CPU and memory to your applications.
                            Lower your cloud bill by deploying fewer nodes for the same containers</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/instance-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Pick the Right Instances for Your Kubernetes
                              Nodes</a></h4>
                          <p>Determine the right instance families and types for your nodes.
                            Determine the right node configuration for your cluster auto-scaling needs</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/kubernetes-namespace-icon.png" style={{ width:"60px" }} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Optimize Resource Allocations for Namespaces &
                              Clusters</a></h4>
                          <p>Ensure cluster and namespace quotas are aligned with application requirements,
                            Reduce costs by ensuring quotas don’t provision resources that go unused</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/ec2-icon.png" style={{width:"100px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon EC2 Optimization</a></h4>
                          <p>Finops cloud machine learning analyzes workload patterns to determine the optimal size and family
                            for your AWS Elastic Compute Cloud (EC2) instances</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/amazon-rds.icon.png" style={{width: "80px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon RDS Optimization</a></h4>
                          <p>Optimize your AWS Relational Database Services (RDS) using FinopsCloud’s machine learning based
                            analysis</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/amazon-asg.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon ASG Optimization</a></h4>
                          <p>Finops cloud analyzes the utilization and scaling behavior of AWS Auto Scaling Groups (ASGs) and
                            performs simulations to determine their optimal configuration</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/eks-icon.png" style={{width: "60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Amazon EKS Clusters Optimization</a></h4>
                          <p>Finops cloud analytics predictively, precisely and continuously determine the appropriate resource
                            settings for your AWS Elastic Kubernetes Service (EKS) containers</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/visualize-metrics-gcp-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Visualize Utilization Metrics for Your AWS
                              Infrastructure</a></h4>
                          <p>Provides instant access to visualize CPU, Memory, Network and Disk I/O utilization metrics for your
                            EC2, RDS, and ASG instances</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/azure-vm-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Azure VM Optimization​</a></h4>
                          <p>Finops cloud machine learning analyzes workload patterns to determine the optimal size and family
                            for your Azure Virtual Machines (VMs)</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/azure-aks-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Azure AKS Optimization​</a></h4>
                          <p>Finops cloud analytics predictively, precisely and continuously determine the appropriate resource
                            settings for your Azure Kubernetes Service (AKS) containers</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/visualize-metrics-gcp-icon.png" style={{width: "60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Visualize Utilization Metrics for Your Azure
                              Infrastructure</a></h4>
                          <p>Provides instant access to visualize CPU, Memory, Network and Disk I/O utilization metrics for your
                            Azure VMs</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/gce-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Google Cloud GCE Optimization</a></h4>
                          <p>Finops cloud machine learning analyzes workload patterns to determine the optimal size and family
                            for your GCP Compute Engine (GCE) instances</p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon-box">
                          <div className="icon"><img src="assets/images/Kubernetes-icon.png" style={{width:"60px"}} /></div>
                          <h4 className="title"><a href="" className="stretched-link">Google Cloud GKE Optimization</a></h4>
                          <p>Finops cloud analytics predictively, precisely and continuously determine the appropriate resource
                            settings for your GCP Kubernetes Engine (GKE) containers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </main>
    );
}

export default SolutionsComponent;