import { Link } from "react-router-dom";

const ResourcesComponent = () => {
    return (
          <main id="main">
          <div className="breadcrumbs">
            <div className="page-header d-flex align-items-center">
              <div className="container position-relative">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 text-center">
                    <h2 className="text-center">Our Blogs</h2>
                    <p className="text-center">Get the latest in cloud infrastructure and cost optimization tactics that include
                      step-by-step tutorials, how-to’s, and industry updates and trends.</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-6 text-center">
                    <div className="search-form">
                      <form action="#">
                        <input type="text" placeholder="Search..."/>
                        <button><i className="fa fa-search"></i></button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <section className="single-page">
            <div className="container" data-aos="fade-up">
              <div id="recent-posts" className="recent-posts">
                <div className="container" data-aos="fade-up">
                  <div className="row gy-4">
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-1.jpg" alt="" className="img-fluid" />
                        </div>
                        <p className="post-category">Amazon Web Services</p>
                        <h2 className="title">
                          <a href="blogs-details.html">5 AWS Spot Reliability Myths Holding Back Your Savings</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Chandan Kumar</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">Feb 1, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-2.jpg" alt="" className="img-fluid" />
                        </div>
                        <p className="post-category">Amazon Web Services</p>
                        <h2 className="title">
                          <a href="blogs-details.html">EC2 Auto Scaling: The Basics, Best Practices, Challenges and More</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Shalini Kumari</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">Oct 5, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-3.jpg" alt="" className="img-fluid"/>
                        </div>
                        <p className="post-category">Amazon Web Services</p>
                        <h2 className="title">
                          <a href="blogs-details.html">AWS Reserved Instance (RI) Changes: What EDP Customers Need to Know</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Nimish Mehta</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">Dec 22, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-4.jpg" alt="" className="img-fluid" />
                        </div>
                        <p className="post-category">Optimization</p>
                        <h2 className="title">
                          <a href="blogs-details.html">Our Cloud Cost Optimization Framework That Works</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Pradosh Kumar</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">Jan 22, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-5.jpg" alt="" className="img-fluid" />
                        </div>
                        <p className="post-category">Saving</p>
                        <h2 className="title">
                          <a href="blogs-details.html">Maximize Long-Term Savings From Cloud Providers with FinOps Cloud</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Ramneck Khurana</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">October 22, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                    <div className="col-lg-4">
                      <article>
                        <div className="post-img">
                          <img src="assets/images/blog/blog-6.jpg" alt="" className="img-fluid" />
                        </div>
                        <p className="post-category">Kubernetes</p>
                        <h2 className="title">
                          <a href="blogs-details.html">
                            Optimizing Your Kubernetes Nodes Using FinOps Tools? Try This Instead…</a>
                        </h2>
                        <div className="d-flex align-items-center">
                          <div className="post-meta">
                            <p className="post-author">Shalini Kumari</p>
                            <p className="post-date">
                              <time dateTime="2022-01-01">Dec 29, 2022</time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
      
                  </div>
      
                </div>
              </div>
            </div>
          </section>
      
        </main>
    );
}

export default ResourcesComponent;