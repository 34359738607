const ResOptimizationComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="row d-flex">
                          <h3>Resource optimization within finops strategy</h3>
                          <p>Equally important but very different.</p>
                      </div>
                      <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/multi-cloud.png" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <div className="single-page">
              <div id="Solutions" className="section">
                <div className="top-icon-box position-relative">
                  <div className="container position-relative">
                    <div className="section-header">
                      <h3>Comprehensive FinOps Programs include Resource Optimization</h3>
                    </div>
                    <p>
                      “FinOps” as defined by the FinOps Foundation includes “Usage Optimization” as a key element.
                    </p>
                    <p>
                      Almost every enterprise struggles with this because Finance doesn’t control infrastructure and getting Engineers and Developers to make changes is the most commonly reported challenge.
                    </p>
                    <p>As a result, significant optimization is left on the table.
                    </p>
                    <p>
                    FinopsCloud overcomes these challenges by providing:
                    </p>
                    <ul>
                      <li><i className="bi bi-arrow-right-circle-fill"></i> Precision analytics that examine workloads and key constraints deeply to provide safe actions that will be trusted by application owners</li>
                      <li><i className="bi bi-arrow-right-circle-fill"></i> Flexible ways to integrate into processes and approval workflows that initiate execution and can end in automated application of the changes</li>
                    </ul>
                    <p>FinopsCloud does not compete with FinOps processes and tools. The focus is on tackling this huge challenge by matching application demands to the right resources and instances at the container or cloud instance level.</p>
                    <p>
                      When resources are optimized, cloud bills get smaller and apps run better.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
    );
}

export default ResOptimizationComponent;