const FooterComponent = () => {
    return (
        <footer id="footer" className="footer-section">
        <div className="container">
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="landing" className="logo d-flex align-items-center">
                                <img src="assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                            <div className="footer-text">
                                <p>FinopsCloud is an esteemed organisation in the industry of cloud computing where we focus our customers to serve the best by providing cost effective and accurate usable cloud service.</p>
                            </div>
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <a href="#"><i className="fa fa-facebook facebook-bg"></i></a>
                                <a href="#"><i className="fa fa-twitter twitter-bg"></i></a>
                                <a href="#"><i className="fa fa-google google-bg"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                              <li><a href="product">Products</a></li>
                              <li><a href="solutions">Solutions</a></li>
                              <li><a href="pricing">Pricing</a></li>
                              <li><a href="resources">Resources</a></li>
                              <li><a href="contact">Contact</a></li>
                              <li><a href="terms">Terms & Condition</a></li>
                              <li><a href="privacy">Privacy & Policy</a></li>
                              <li><a href="security">Security</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Start now with FinopsCloud.ai</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Discover how much you could save by connecting your infrastructure with FinopsCloud.ai for free.
                                </p>
                            </div>
                            <div className="subscribe-form">
                                <form action="#">
                                    <input type="text" placeholder="Email Address" />
                                    <button><i className="fa fa-telegram"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-left text-lg-left">
                        <div className="copyright-text">
                            <p>FinopsCloud © 2023</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block">
                        <div className="footer-menu text-right">
                          <ul>
                            <li><a href="terms">Terms & Condition</a></li>
                            <li><a href="privacy">Privacy & Policy</a></li>
                            <li><a href="security">Security</a></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    );
}

export default FooterComponent;