const OptimizerComponent = () => {
  return (
    <main id="main">
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="row d-flex">
                  <h3>Multi-Cloud resource Optimizer</h3>
                  <p>Monitor you mulit cloud workloads in single dashboard</p>
                </div>
                <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
              </div>
              <div className="col-lg-6">
                <img src="assets/images/cost-optimize-header.png" className="img-fluid rounded-4 mb-4" alt="" style={{ marginTop: "-80px", marginLeft: "150px", width: "500px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-header">
        <br />
        <h4>FinopsCloud offers cloud resource optimization for </h4>
        <br/>
        <div className="row gy-4">
          <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
            style={{ margin: "auto", width: "500px" }} alt="" />
        </div>
      </div>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>You Have Financial Visibility Covered. Now, It’s Time to Optimize.
            </h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <p>Optimizing cloud resources means going beyond financial analysis to impact resource efficiency.
                  Choosing sub optimal instances can be a cost or performance disaster in the cloud:​ </p>
                <ul>
                  <li>Compare results between accounts, business groups, applications, or by any other tag
                  </li>
                  <li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                  </li>
                  <li>Assess the impact of implementing optimization recommendations
                  </li>
                  <li>Calculate financial impacts and estimated savings</li>
                </ul>
                <p>Read more about <a href="#">resource optimization vs cloud cost management</a>.​</p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-monitoring.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about landing-cloud">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h3>
                  You Have Infra Workload Visibility?
                </h3>
                <br />
                <p>
                  Achieve Top-Down Capacity Visibility Across Your Cloud Infrastructure
                  FinopsCloud combines detailed analysis of thousands of entities into a dynamic view of the scope,
                  efficiency, and health across all your cloud environments. This lets you:
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li>
                    <li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li>
                    <li>Assess the impact of implementing optimization recommendations
                    </li>
                    <li>Calculate financial impacts and estimated savings</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  You Have Multi Cloud Visibility?
                </h2>
                <br />
                <p>
                  FinopsCloud automatically produces Impact Analysis and Recommendation Reports to share with stakeholders,
                  letting you:
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li>
                    <li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li>
                    <li>Assess the impact of implementing optimization recommendations</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-optlize.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default OptimizerComponent;