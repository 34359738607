import { Link } from "react-router-dom";

const HomeComponent = () => {
  return (
    <main id="main">
      <section id="hero" className="hero">
        <div className="container position-relative">
          <div className="row gy-5" data-aos="fade-in">
            <div
              className="col-lg-12  order-lg-1 d-flex flex-column align-items-start justify-content-center text-left caption">
              <h2 className="landing-heading"><span style={{ color: "#034099" }}>AI - Based</span> Multi-cloud Finops Tool</h2>
              <h3 data-aos="fade-left" data-aos-delay="1000">Helping to make your cloud experience cost effective</h3>
              <div className="d-flex" data-aos-delay="2000" data-aos="zoom-out">
                <a href="book-demo" className="btn-get-started">REQUEST A DEMO</a>&nbsp;
                <a href="#about" className="btn-get-started" style={{ background: "linear-gradient(140deg, #4b005a 34%, #4301f4 100%)", color: "aliceblue" }}>GET STARTED</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
      <br/>
        <h2>The Cloud Optimization Platform For</h2>
        <div className="row gy-4">
          <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
            style={{ margin: "auto", width: "500px" }} alt="" />
        </div>
        <p>We make it easy to get started with our free Cloud Management Platform and multiple products that are
          based on your cloud savings.</p>
      </div>

      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Managing Cloud Resource Cost Effectively
                </h2>
                <br />
                <p>
                  Every Cloud resource procurement adds up to cost for Engineering organisations. So it's important to
                  procure resources as much required and avoid over payment.
                </p>
                <p>
                  Cloud Waste is quite common in organisations due to various resons like over estimation, unused and
                  ideal resources
                </p>
                <p>
                  Performance evaluation Benchmark is not properly implemented to monitor cloud infrastructure
                </p>
                <p>
                  Lack of proper or accurate forecasting due to absence or inadequate finops management resource.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/Cloud-Cost-optimization.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about landing-cloud">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>FinopsCloud is one stop ideal solution for all the optimization needs</h2>
            <p>As much the industry getting more into cloud infrastructure the flexibility is also appearing with overhead
              of managing and mantaining single or multi cloud infrastructures where we help you to manage your resource
              with minimal operational overheads</p>
          </div>

          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <ul>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> We optimize your available cloud resource in various
                    ways</li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Availing Services that needed for the required time
                  </li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Decommissioning unused or overprocured resources </li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Monitoring service usage at runtime to set performance
                    bencmark </li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Improving Cloud investment literacy as per
                    organisation budget </li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Set up Finops team with accurate knowledge to Forecast
                    real cost</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section id="whyus" className="about landing-book">
        <div className="container" data-aos="fade-up">
          <div className="d-flex" data-aos-delay="500" data-aos="zoom-out">
            <p className="landing-booking">
              Let us help you start saving today!
              <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
            </p>
          </div>
        </div>
      </section>

      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Optimize Cloud Resources Budget Friendly
                </h2>
                <br />

                <p>
                  We will optimize your cloud resources by avoiding resource wastage with proper forecasting, monitoring
                  and notification of services with respect to usage or non activity.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/Cost_Management-2.jpg" className="img-fluid rounded-4 mb-4 hero-icon" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="whyus" className="about" style={{ background: "linear-gradient(140deg, #0021630a 34%, #0071e900 100%)" }}>
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/kubernetes.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Cloud Resources Cost Monitoring at Runtime
                </h2>
                <br />
                <p>
                  Let us help you manage your savings plans and get you discounts across all your AWS usage with our
                  efficient tool.
                  Algorithms are used to track cloud costs at each individual service, region, team, and instance type
                </p>
                <p>
                  Our efficient Algorithms are used to track cloud costs at each individual service, region, team, and
                  instance type.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Cloud Resource Workload Monitoring at Runtime
                </h2>
                <br />
                <p>
                  Our tool constantly monitors the workload on the procured resources.
                </p>
                <p>
                  We monitor your usage pattern by which We find the actual required resource considering high performance
                  and also identify idle resources and turn them off automatically to save you expenses on your Cloud
                  bill.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/landing-lifecycle.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="whyus" className="about" style={{ background: "linear-gradient(140deg, #0021630a 34%, #0071e900 100%)" }}>
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/right-sizing-click2cloud.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  Real-time Cloud Services Recommendations/Alert to Save Cloud Spending
                </h2>
                <br />
                <p>
                  Apart from monitoring and optimization,Let us suggest you ideal usage and resources as per your
                  requirement. We also provide on premise integaraton FinopsCloud.
                </p>
                <p>
                  Consider demand management, service management and monitoring.
                </p>
                <p>
                  Develop continuous cloud optimization standards. Continious monitoring of newly procured resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="whyus" className="about landing-resources">
        <div className="container" data-aos="fade-up">
          <div className="d-flex" data-aos-delay="1000" data-aos="zoom-out">
            <h2 className="landing-booking">Learn More About Finops Cloud AI</h2>
          </div>
          <p className="landing-resources-btn">
            Multi cloud financial operation AI tool
          </p>
          <p className="landing-resources-btn">
            <a href="#about" className="btn-get-started btn-landing">See more resources</a>
          </p>
        </div>
      </section>
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>No complex assembly required!</h2>
            <p>We are determined to serve you at utmost feasibility and minimal operational overhead. Our software works
              with industry leading cloud platforms, Solutions, and tools so that you can simplify and automate your cloud
              infrastructure wherever your workloads and applications run and however you run them.</p>
          </div>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h3>
                  Your resource+FinopsCloud = Reduced Cost
                </h3>
                <ul>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> One stop solution for cloud optimization needs</li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> Easy to integrate with minimal effort</li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> AI Powered to improve cost and service monitoring
                    continiously</li>
                  <li><i className="bi bi-arrow-right-circle-fill"></i> We customize as per your infrastructure requirement
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/kubernetes.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>

        </div>
      </section>


      <section id="whyus" className="about" style={{ background: "linear-gradient(140deg, #0021630a 34%, #0071e900 100%)" }}>
        <div className="container landing-resources blue-box" data-aos="fade-up" style={{ borderRadius: "30px" }}>
          <div className="d-flex" data-aos-delay="1000" data-aos="zoom-out">
            <h2 className="landing-booking">Start now with FinopsCloud.ai</h2>
          </div>
          <p>
            Discover how much you could save by connecting your infrastructure with FinopsCloud.ai for free.
          </p>
          <div className="subscribe-form" style={{ width: "500px", marginLeft: "350px" }}>
            <form action="#">
              <input type="text" placeholder="Email Address" />
              <button><i className="fa fa-telegram"></i></button>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
}

export default HomeComponent;