const ComingSoonComponent =() => {
    return (
        <>
          <div id="coming-soon-background"></div>
            <div className="coming-soon">
              <div id='coming-soon'>
                <div className='title'>
                  <span>Finopscloud - AI based tool</span>
                </div>
                <p>coming soon</p>
                <section>
                  <ul id="countdown">
                    <li><span className="days timenumbers">90</span>
                      <p className="timeRefDays timedescription">days</p>
                    </li>
                    <li><span className="hours timenumbers">07</span>
                      <p className="timeRefHours timedescription">hours</p>
                    </li>
                    <li><span className="minutes timenumbers">20</span>
                      <p className="timeRefMinutes timedescription">minutes</p>
                    </li>
                    <li><span className="seconds timenumbers yellow-text">45</span>
                      <p className="timeRefSeconds timedescription">seconds</p>
                    </li>
                  </ul>
                </section>
                <p>Click here for goto</p>
                <p><a href="landing" className="fill-btn"> Home</a></p>
              </div>
            </div>
        </>
    );
}
export default ComingSoonComponent;