const ResOptimizationCloudCostComponent = () => {
    return (
          <main id="main">
            <div className="breadcrumbs">
              <div className="page-header d-flex align-items-center">
                <div className="container position-relative">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="row d-flex">
                          <h3>Resource optimization vs cloud cost management</h3>
                          <p>Equally important but very different.</p>
                      </div>
                      <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/images/multi-cloud.png" className="img-fluid rounded-4 mb-4" alt="" style={{marginTop: "0px", marginLeft: "150px", width: "300px"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-page">
              <div id="Solutions" className="section">
                <div className="top-icon-box position-relative">
                  <div className="container position-relative">
        
                    <div className="section-header">
                      <h3>Why does Finops Cloud AI focus on workload resource optimization?</h3>
                    </div>
        
                    <p>
                      There are two major thrusts associated with achieving cost efficient cloud and container infrastructure:
                    </p>
                    <ul>
                      <li><i className="bi bi-arrow-right-circle-fill"></i> Governance and Financial Management, with includes control of budgeting and forecasting, cross charging, cloud contract commitments and invoice analytics.</li>
                      <li><i className="bi bi-arrow-right-circle-fill"></i> Controlling the consumption of resources at the infrastructure level, which includes analyzing workloads to ensure that the right type and quantity of capacity is available without being wasteful.</li>
                    </ul>
                    <p>Both disciplines are very important and demanding but serve significantly different functions and users.</p>
                    <p>
                    FinopsCloud focuses on #2 and the precision required to be part of the infrastructure management chain. This focus and the resulting depth of capabilities means that our recommendations can be safely applied to infrastructure and increasingly automated. 
                    </p>
                    <p>
                      These recommendations cannot be basic “suggestions”. Rather, they must be accurate directives that are the result of both rigorous workload analysis and policies that are deep enough to reflect the unique needs of enterprises, departments, or individual workloads. 
                    </p>
        
                    <p>
                      A FinopsCloud demo or trial is a rapid way to compare what we do vs other solutions. 
                    </p>
        
                      <p className="landing-booking" style={{paddingBottom: "40px", color: "black"}}>
                        Let us help you start saving today!
                      <a href="/" className="btn-get-started btn-landing" style={{background:"#0071e9", color: "aliceblue"}}>REQUEST A DEMO</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
    );
}

export default ResOptimizationCloudCostComponent;