const CostMonitoringComponent = () => {
  return (
    <main id="main">
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="row d-flex">
                  <h3>Multi-Cloud Cost Monitoring</h3>
                  <p>Monitoring multiple cloud costs thst being used</p>
                </div>
                <a href="book-demo" className="btn-get-started btn-landing">REQUEST A DEMO</a>
              </div>
              <div className="col-lg-6">
                <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4 blue-box " alt="" style={{ marginTop: "-80px", marginLeft: "150px", width: "500px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-header">
        <br />
        <h4>FinopsCloud offers cost monitoring for</h4>
        <br/>
        <div className="row gy-4">
          <img src="assets/images/multi-cloud-pricing.png" className="img-fluid rounded-4 mb-4"
            style={{ margin: "auto", width: "500px" }} alt="" />
        </div>
      </div>
      <section id="whyus" className="about landing-cloud">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <img src="assets/images/finopscloud-dashboard.png" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h3>
                Multi cloud cost monitoring helps to increase utilized cost visibility
                </h3>
                <br />
              
            <ul>
              <li>Optimizing cloud cost leads to financial visibility which helps use available resources efficiency. 
              </li>
              <li>Choosing
              sub optimal instances can be a cost or performance disaster in the cloud
              </li>
              <li>Comparing multicloud will give the visibility to use cost effictive provider
              </li>
              <li>We provide rating w.r.t multiple clouds, amount of risk, savings potential, and other KPIs
              </li>
              <li>Calculate financial impacts and estimated savings</li>
              <li>Assessing the above parameters helps for a better recomendation of cloud provider
              </li>
            </ul>                
                
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section id="whyus" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <h2>
                  You Have Multi Cloud Visibility?
                </h2>
                <br />
                <p>
                  FinopsCloud automatically produces Impact Analysis and Recommendation Reports to share with stakeholders,
                  letting you:
                  <ul>
                    <li>Compare results between accounts, business groups, applications, or by any other tag
                    </li>
                    <li>Provide rankings by efficiency rating, amount of risk, savings potential, and other KPIs
                    </li>
                    <li>Assess the impact of implementing optimization recommendations</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="assets/images/product-optlize.jpg" className="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CostMonitoringComponent;